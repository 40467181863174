module.exports = () => {
    /* TODO: refactoring of this code, separate common game menu,
     error handling and adding asynchronous execution */
    let moduleGameResize = require('../component/resize_game_iframe.js');
    let moduleToggler = require('../component/toggle_one_all.js');

    let IFRAME_RESIZE = moduleGameResize();
    let TOGGLER = moduleToggler();

    let body = document.querySelector('body');
    let layout = document.querySelector('[data-key=game_play]');

    let aspectRatio = document.querySelector('[data-aspect_ratio]');
    let gameRatio = 16 / 9; // 16x9 ratio by default

    body.querySelector('.main').style.paddingTop = '0px';

    body.classList.add('game_unset_styles');

    /* for each VAVADA game (standart and tournament) */

    TOGGLER.TOGGLE_CONTENT('display_none');

    if (window.innerWidth >= 1024) {
        function INVOCATION_GUI() {
            if (window.innerWidth < 1024) {
                body.style.height = window.innerHeight + 'px';
                body.style.width = window.innerWidth + 'px';
                body.classList.add('game_unset_styles');
            } else {
                body.style.height = 'auto';
                body.style.width = 'auto';
                body.classList.remove('game_unset_styles');
            }
        }

        function GAME_MENU_ALIGNMENT() {
            let iframeWrapper = document.querySelector('[data-resize=shape][data-game=play]');
            let gameMenu = document.querySelector('.game_menu');
            gameMenu.style.maxHeight = iframeWrapper.style.height;
        }

        INVOCATION_GUI();
        window.addEventListener('resize', INVOCATION_GUI);

        if (aspectRatio) { // ratio from server
            let arraySplitRatio = aspectRatio.getAttribute('data-aspect_ratio').split(/[:]/);
            gameRatio = arraySplitRatio[0] / arraySplitRatio[1];
        }

        IFRAME_RESIZE(
            '[data-resize=shape][data-game=play]',
            (gameRatio),
            '[data-obstacle=vertical]',
            '[data-obstacle=horizontal]',
            '[data-key=game_play]'
        );

        GAME_MENU_ALIGNMENT();
        window.addEventListener('resize', GAME_MENU_ALIGNMENT);
    } else {
        const gameMenu = document.querySelector('[data-game=menu]');
        if (gameMenu) {
            gameMenu.classList.add('mobile');
            gameMenu.classList.add('display_none');

            const moduleGameMenuBtns = require('../component/game_menu_btns.js');
            moduleGameMenuBtns(gameMenu);
        }

        /* additional scripts for IOS GUI nice view start */

        let guiMobile = document.querySelectorAll('[data-gui=mobile]');
        let stylesArr = [];

        guiMobile.forEach((element) => {
            let initStyleTop = parseFloat(getComputedStyle(element).top);
            stylesArr.push(initStyleTop);
        });

        window.addEventListener('scroll', () => {
            if (window.pageYOffset === 0) {
                guiMobile.forEach((element, id) => {
                    element.style.top = stylesArr[id] + 'px';
                });
            }
        });

        function FIX_GAME_VIEW() {
            let delta = document.documentElement.offsetHeight - window.innerHeight;

            if (delta > 0 && window.innerHeight < window.innerWidth) {
                guiMobile.forEach((element, id) => {
                    element.style.top = stylesArr[id] + (delta / 2) + 'px';
                });

            } else {
                guiMobile.forEach((element, id) => {
                    element.style.top = stylesArr[id] + 'px';
                });
            }
        }

        window.addEventListener('resize', FIX_GAME_VIEW);

        /* additional scripts for IOS GUI nice view end */
    }

    layout.classList.add('x_ready');

    /* ladder */
    if (document.querySelector('[data-ladder]')) {
        const moduleAjaxList = require('../component/ajax_html.js');
        const AJAX_LADDER = moduleAjaxList();
        AJAX_LADDER('[data-ajax=html]');
    }

    /* chat */
    if (document.getElementById('chat')) {
        const chatWebSocket = require('../component/chat.js');
        chatWebSocket();
    }

    /* progress */
    const progressBlock = document.querySelector('[data-progress-url]');
    if (progressBlock) {
        const userProgress = require('../component/user_progress.js');
        userProgress(progressBlock.dataset.progressUrl);
    }
};
