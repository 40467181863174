module.exports = (gameMenu) => {
    const btnMenuMobileOpen = document.querySelector('[data-game=btn_menu_mobile_open]');
    const btnMenuMobileClose = document.querySelector('[data-game=btn_menu_mobile_close]');

    btnMenuMobileOpen.classList.remove('display_none');
    btnMenuMobileOpen.addEventListener('click', (event) => {
        event.preventDefault();
        gameMenu.classList.remove('display_none');
        btnMenuMobileClose.classList.remove('display_none');
        btnMenuMobileOpen.classList.add('display_none');
    });

    btnMenuMobileClose.addEventListener('click', (event) => {
        event.preventDefault();
        gameMenu.classList.add('display_none');
        btnMenuMobileOpen.classList.remove('display_none');
        btnMenuMobileClose.classList.add('display_none');
    });
};
