const Fetch = require('../tools/fetch.js');
const gamecardFavorite = require('../component/gamecard/favorite.js');
const gamecardShow = require('../component/gamecard/show.js');

module.exports = () => {
    const findNewGamecards = newGameList => {
        gamecardFavorite(newGameList);
        gamecardShow(newGameList);
    };

    let gameCatalog = document.querySelectorAll('[data-game=catalog]');
    gameCatalog.forEach((catalog) => {
        let showMore = catalog.querySelectorAll('[data-show_more]');

        if (showMore.length === 0) {
            return;
        }

        let lists;
        let initList = catalog.querySelector('[data-game=list]');
        let initUrl = window.location.href;
        initList.setAttribute('data-url', initUrl);
        let loading = false;

        function UPDATE_ALL_GAME_LISTS() {
            lists = catalog.querySelectorAll('[data-game=list]');
        }

        function CHECK_RANGE_AND_REPLACE_URL() {
            let arrayMinRange = [];

            lists.forEach((list) => {
                let listBoundings = list.getBoundingClientRect();
                /* add some padding for nice view of pagination */
                let paddingScroll = (parseFloat(getComputedStyle(list).height)) / 2;
                let rangeTop = listBoundings.top + window.pageYOffset + paddingScroll;

                list.minRange = Math.abs((window.pageYOffset - rangeTop));
                arrayMinRange.push(list.minRange);
            });

            let minRangeFromArray = Math.min.apply(Math, arrayMinRange);

            lists.forEach((list) => {
                const listUrl = list.getAttribute('data-url');
                const isCurrentUrl = history.state && history.state.prev === listUrl;

                if (list.minRange === minRangeFromArray && !isCurrentUrl) {
                    history.replaceState({ prev: listUrl }, null, listUrl);
                }
            });
        }

        showMore.forEach((button) => {
            let buttonStatus = button.getAttribute('data-show_more');

            function CATALOG_INVOKE() {
                let url = button.getAttribute('href');
                loading = true;

                Fetch(url)
                    .then(response => response.json())
                    .then(jsonValue => {
                        let newGameList = document.createElement('ul');

                        newGameList.setAttribute('data-url', url);
                        newGameList.setAttribute('data-game', 'list');
                        newGameList.classList.add('game-catalog_list');
                        newGameList.innerHTML = jsonValue.html;

                        switch (buttonStatus) {
                            case 'next':
                                catalog.insertBefore(newGameList, button);
                                button.setAttribute('href', jsonValue.next_url);
                                break;
                            case 'previous':
                                catalog.insertBefore(newGameList, button.nextSibling);
                                button.setAttribute('href', jsonValue.prev_url);
                                break;
                        }

                        findNewGamecards(newGameList);

                        window.removeEventListener('scroll', CHECK_RANGE_AND_REPLACE_URL);
                        UPDATE_ALL_GAME_LISTS();
                        CHECK_RANGE_AND_REPLACE_URL();
                        window.addEventListener('scroll', CHECK_RANGE_AND_REPLACE_URL);

                        let finishHref = button.getAttribute('href');

                        if (finishHref === null || finishHref === 'null') {
                            button.classList.add('display_none');
                        }
                    })
                    .catch(reject => {
                        if (reject instanceof Response) {
                            document.location.reload();
                        }
                    })
                    .finally(() => loading = false);
            }

            button.addEventListener('click', (event) => {
                event.preventDefault();

                if (loading) {
                    return;
                }

                CATALOG_INVOKE();
            });
        });
    });
};
