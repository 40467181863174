module.exports = (inputSelector, symbolsToRemoveRegExp, blockLength) => {
    function FORMAT(value, symbolsToRemoveRegExp, blockLength) {
        let v = value.replace(symbolsToRemoveRegExp, '').toUpperCase();
        let matches = v.match(new RegExp('.{' + blockLength + ',100}', 'g'));
        let match = matches && matches[0] || '';
        let parts = [];

        for (let i = 0, len = match.length; i < len; i += blockLength) {
            parts.push(match.substring(i, i + blockLength));
        }

        if (parts.length) {
            return parts.join(' ');
        } else {
            return v;
        }
    }

    let inputMethodNumber = document.querySelectorAll(inputSelector);

    inputMethodNumber.forEach((item) => {
        item.addEventListener('input', () => {
            item.value = FORMAT(item.value, symbolsToRemoveRegExp, blockLength);
        });
    });
};
