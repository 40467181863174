module.exports = selectEntity => {
    // TODO: resolve bug - excess empty object in array
    if (selectEntity.passedElement) {
        const element = selectEntity.passedElement.element;

        if (element.hasAttribute('required')) {
            const list = selectEntity.dropdown.element.querySelector('.choices__list');

            element.addEventListener('showDropdown', () => {
                const firstDisabled = list.querySelector('.choices__item--disabled');
                const choiceValue = firstDisabled.childNodes[0].nodeValue.replace(/\n/, '').trim();

                if (choiceValue === '') {
                    firstDisabled.classList.add('x_empty');
                }
            });
        };
    }
};
