module.exports = () => {
    let moduleTimer = require('../../component/timer.js');
    let CREATE_TIMER = moduleTimer();

    let allTimers = document.querySelectorAll('[data-tournament=timer]');

    allTimers.forEach((timer) => {
        let timerView = timer.querySelector('[data-tournament=result]');
        let time = timer.getAttribute('data-remaining-seconds');

        CREATE_TIMER(timerView, time);
    });
};
