module.exports = (url, options = {}) => {
    options.headers = options.headers ? options.headers : {};
    options.headers['X-Requested-With'] = 'XMLHttpRequest';

    return fetch(url, options)
        .then(response => {
            if (!response.ok) { throw response; }

            return response;
        })
        .catch(rejection => {
            throw rejection; // TODO: add errors log
        });
};
