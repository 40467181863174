const Choices = require('choices.js');
const ChoicesScroll = require('./choices_scroll.js');
const ChoicesDisabled = require('./choices_disabled_empty_option.js');

module.exports = (selector, params) => {
    function init(entity) {
        ChoicesScroll(entity);
        ChoicesDisabled(entity);
    }

    const selects = new Choices(selector, params);
    if (Array.isArray(selects)) {
        selects.forEach(selectEntity => init(selectEntity));
    } else {
        init(selects);
    }
};
