module.exports = () => {
    const ladderCarousel = document.querySelector('[data-ladder-carousel=glide]');
    if (ladderCarousel) {
        const Glide = require('@glidejs/glide/dist/glide.js');
        const carousel = new Glide('.glide').mount();
        const slides = ladderCarousel.querySelectorAll('.glide__slide');
        const slidePosition = ladderCarousel.querySelector('[data-ladder-carousel=slide-position]');

        if (slidePosition && slides[0]) {
            carousel.on('move.after', () => {
                slidePosition.innerText = `${carousel.index + 1}/${slides.length}`;
            });
        }
    }
};
