module.exports = () => {
    const windowMinWidth = require('../constants/breakpoints.js').windowMobileBreakpoint;
    const windowTabletWidth = require('../constants/breakpoints.js').windowTabletBreakpoint;
    const cornerRadiusTop = 7;
    const cornerRadiusBottom = 15;
    const paddingTop = 12;
    const paddingHorizontal = 4;
    const dotsImposition = 4;
    const delay = 100;
    let orientationTimeout = null;
    const dotsColor = {
        slots: '#109e93',
        live: '#de593e',
        casino: '#6ef770',
        tournament: '#e8ce3a',
        sports: '#9a5cff',
    };

    class NavigationDots {
        constructor(beam) {
            this.beam = beam;
            this.activeCategory = beam.querySelector('[data-navigation=active]');
            this.color = dotsColor[this.activeCategory.getAttribute('data-category')];

            this.canvas = beam.querySelector('[data-navigation=canvas]');
            this.canvas.style.position = 'absolute';
            this.canvas.style.zIndex = 10;
            this.canvas.style.pointerEvents = 'none';
            this.canvas.style.left = 0;

            this.dotYTop = paddingTop;

            this.renderDots = this.renderDots.bind(this);
            this.init();
        }

        renderDots() {
            if (window.innerWidth > windowMinWidth) {
                this.dotYBottom = this.activeCategory.offsetHeight + dotsImposition;

                if (window.innerWidth <= windowTabletWidth) {
                    this.canvas.height = 95;
                } else {
                    this.canvas.height = 70;
                }

                const { dotYTop } = this;
                const { dotYBottom } = this;
                const boundings = this.activeCategory.getBoundingClientRect();

                this.canvas.style.display = 'block';

                /* calculation from top of document,
                window.scrollY not supported in IE11, instead we use window.pageYOffset*/
                this.canvas.style.top = Math.round(window.pageYOffset + boundings.top) + 'px';
                this.canvas.width = document.documentElement.clientWidth;

                const dotsContext = this.canvas.getContext('2d');
                dotsContext.lineWidth = 2;
                dotsContext.strokeStyle = this.color;
                dotsContext.lineCap = dotsContext.lineJoin = 'round';
                dotsContext.setLineDash([0, 5]);

                const dotXLeft = boundings.left + paddingHorizontal;
                const dotXRight = boundings.right - paddingHorizontal;

                dotsContext.beginPath();
                dotsContext.moveTo(0, dotYBottom);
                dotsContext.lineTo(dotXLeft - cornerRadiusBottom, dotYBottom);
                dotsContext.arc(
                    dotXLeft - cornerRadiusBottom,
                    dotYBottom - cornerRadiusBottom,
                    cornerRadiusBottom,
                    0.5 * Math.PI, 0, true);
                dotsContext.lineTo(dotXLeft, dotYTop + cornerRadiusTop);
                dotsContext.arc(
                    dotXLeft + cornerRadiusTop,
                    dotYTop + cornerRadiusTop,
                    cornerRadiusTop,
                    Math.PI, 1.5 * Math.PI, false);
                dotsContext.lineTo(dotXRight - cornerRadiusTop, dotYTop);
                dotsContext.arc(
                    dotXRight - cornerRadiusTop,
                    dotYTop + cornerRadiusTop,
                    cornerRadiusTop,
                    1.5 * Math.PI, 0,  false);
                dotsContext.lineTo(dotXRight, dotYBottom - cornerRadiusBottom);
                dotsContext.arc(
                    dotXRight + cornerRadiusBottom,
                    dotYBottom - cornerRadiusBottom,
                    cornerRadiusBottom,
                    Math.PI, 0.5 * Math.PI, true);
                dotsContext.lineTo(this.canvas.width, dotYBottom);
                dotsContext.stroke();
            } else {
                this.canvas.style.display = 'none';
            }
        }

        init() {
            this.renderDots();
            window.addEventListener('orientationchange', () => {
                clearTimeout(orientationTimeout);
                orientationTimeout = setTimeout(() => this.renderDots(), delay);
            });
            window.addEventListener('resize', this.renderDots);
        }
    }

    return NavigationDots;
};
