require('../../tools/append_children.js');
const QS = require('qs');
const Fetch = require('../../tools/fetch');
const { windowMobileBreakpoint } = require('../../constants/breakpoints.js');

const USER_INPUT_TIMEOUT = 200;
const LETTERS_COUNT_TO_START = 3;

module.exports = (input, queryParams) => {
    const keyCode = {
        enter: 13,
        arrowUp: 38,
        arrowDown: 40,
    };

    const form = input.closest('form');
    const list = document.createElement('ul');
    const url = input.dataset.inputSuggestion;
    list.classList.add('input-suggestion');
    input.parentElement.appendChild(list);
    input.addEventListener('focus', scrollOnMobile);

    function listOff() {
        list.classList.remove('x_show');
        document.body.removeEventListener('click', listOff);
    }

    function createListItem(item) {
        const { title, images } = item;
        const listItem = document.createElement('li');
        listItem.setAttribute('tabindex', 0);
        const picture = document.createElement('picture');
        const img = document.createElement('img');
        const arrayOfChildren = [img];

        const sourceMobile = document.createElement('source');
        const sourceDesktop = document.createElement('source');
        sourceMobile.srcset = `${images.mobile.x1}, ${images.mobile.x2} 2x`;
        sourceDesktop.srcset = `${images.desktop.x1}, ${images.desktop.x2} 2x`;
        sourceDesktop.media = `(min-width: ${windowMobileBreakpoint + 1}px`;
        arrayOfChildren.unshift(sourceMobile);
        arrayOfChildren.unshift(sourceDesktop);
        img.src = images.desktop.x1;

        img.classList.add('input-suggestion_img');
        picture.appendChildren(arrayOfChildren);

        const text = document.createElement('span');
        text.innerText = title;
        text.classList.add('input-suggestion_text');

        listItem.appendChild(picture);
        listItem.appendChild(text);
        listItem.classList.add('input-suggestion_item');

        function submit() {
            input.value = title;
            form.submit();
        }

        listItem.addEventListener('click', submit);
        listItem.addEventListener('keydown', event => {
            if (event.which === keyCode.enter) submit();
        });

        return listItem;
    }

    let index = 0;
    let isOrigin = true;
    let originValue;
    let listItems;

    function arrowsHandling(event) {
        if (!list.classList.contains('x_show')) {
            index = 0;
            isOrigin = true;
            return;
        }

        const firstIndex = 0;
        const lastIndex = listItems.length - 1;
        const nextIndex = index + 1;
        const previousIndex = index - 1;

        function init() {
            event.preventDefault();
            listItems[index].classList.remove('x_focus');
        }

        function switchToOrigin() {
            input.value = originValue;
            isOrigin = true;
        }

        function switchToItem() {
            listItems[index].classList.add('x_focus');
            input.value = listItems[index].innerText;
            isOrigin = false;
        }

        switch (event.which) {
            case keyCode.arrowDown:
                init();
                if (nextIndex > lastIndex && !isOrigin) {
                    switchToOrigin();
                    index = firstIndex;
                } else {
                    index = isOrigin ? firstIndex : nextIndex;
                    switchToItem();
                }

                break;
            case keyCode.arrowUp:
                init();
                if (previousIndex < firstIndex && !isOrigin) {
                    switchToOrigin();
                    index = lastIndex;
                } else {
                    index = isOrigin ? lastIndex : previousIndex;
                    switchToItem();
                }

                break;
        }
    }

    function initList(arrayOfSuggestions) {
        listItems = arrayOfSuggestions;
        index = 0;
        isOrigin = true;
        input.removeEventListener('keydown', arrowsHandling);
        input.addEventListener('keydown', arrowsHandling);

        list.innerHTML = '';
        list.appendChildren(listItems);
        list.classList.add('x_show');
        document.body.addEventListener('click', listOff);
    }

    function scrollOnMobile() {
        if (window.innerWidth <= windowMobileBreakpoint) {
            const gap = 10;
            const header = document.querySelector('[data-header]');
            const headerHeight = header && getComputedStyle(header).position === 'fixed'
                ? header.getBoundingClientRect().height
                : 0;

            let { top } = input.getBoundingClientRect();
            top += window.pageYOffset;
            top -= gap;
            top -= headerHeight;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    }

    function fetchData(value, options) {
        const params = { [input.name]: value };
        Object.assign(params, queryParams);

        const encodedUrl = `${url}?${QS.stringify(params)}`;

        Fetch(encodedUrl, options)
            .then(response => response.json())
            .then(dataArray => {
                if (dataArray.length > 0) {
                    const arrayOfSuggestions = dataArray.map(createListItem);
                    initList(arrayOfSuggestions);
                } else {
                    listOff();
                }
            })
            .catch(rejection => {
                if (rejection.name !== 'AbortError') {
                    console.info(rejection, rejection.name, rejection.message);
                }
            });
    }

    let timeout;
    let controller;
    const options = {};

    function clearAndAbort() {
        clearTimeout(timeout);

        if (controller) {
            controller.abort();
        }
    }

    input.addEventListener('input', event => {
        clearAndAbort();

        if (AbortController) {
            controller = new AbortController();
            options.signal = controller.signal;
        }

        const { value } = event.target;
        if (value.length >= LETTERS_COUNT_TO_START) {
            originValue = input.value;
            timeout = setTimeout(() => {
                fetchData(value, options);
            }, USER_INPUT_TIMEOUT);
        }
    });

    input.addEventListener('keydown', event => {
        switch (event.which) {
            case keyCode.arrowDown:
            case keyCode.arrowUp:
                clearAndAbort();
                break;
        }
    });
};
