module.exports = () => {
    let mobileResizeWidth = require('../constants/breakpoints.js').windowMobileBreakpoint;
    let glass = document.querySelector('[data-body=glass]');
    let popUpBtns = document.querySelectorAll('[data-pop_up_btn]');
    let popUpOnLoad = document.querySelector('[data-pop_up_on_load]');
    let popUpForms = document.querySelectorAll('[data-pop_up_form]');
    let elementsForBlur = document.querySelectorAll('[data-blur]');

    function TOGGLE_BODY_BLUR() {
        elementsForBlur.forEach((item) => {
            item.classList.toggle('blur');
        });
    }

    function POP_DOWN_BY_GLASS(event) {
        event.stopPropagation();

        let allPopUps = document.querySelectorAll('[data-pop_up]');

        allPopUps.forEach((popUp) => {
            popUp.classList.add('visibility_hidden');
        });

        elementsForBlur.forEach((item) => {
            item.classList.remove('blur');
        });

        glass.classList.add('display_none');
    }

    function POP_UP(nameSelector) {
        let popUp = document.querySelector(
            '[data-pop_up=' + nameSelector + ']'
        );

        if (popUp === null || popUp === undefined) {
            return false;
        }

        glass.style.height = getComputedStyle(document.body).height;
        glass.classList.toggle('display_none');
        TOGGLE_BODY_BLUR();

        popUp.classList.toggle('visibility_hidden');

        popUp.addEventListener('click', (event) => {
            event.stopPropagation();
        });

        if (window.innerWidth > mobileResizeWidth) {
            popUp.style.top = window.scrollY + (window.innerHeight / 4) + 'px';
        } else {
            popUp.style.top = window.scrollY + (window.innerHeight / 8) + 'px';
        }

        if (nameSelector === 'mobile_menu') {
            popUp.style.top = '0';
            let mobileMenu = popUp.querySelector('[data-mobile=menu]');
            mobileMenu.style.maxHeight = window.innerHeight + 'px';
        }

        if (nameSelector === 'login') {
            popUp.style.top = '0';
            TOGGLE_BODY_BLUR();
        }
    }

    glass.addEventListener('click', POP_DOWN_BY_GLASS);

    popUpBtns.forEach((btn) => {
        btn.addEventListener('click', (event) => {
            event.stopPropagation(); // using to not fall in parents containers

            let popUpName = btn.getAttribute('data-pop_up_btn');

            POP_UP(popUpName);
        });
    });

    popUpForms.forEach((form) => {
        form.addEventListener('submit', (event) => {
            let popUpName = form.getAttribute('data-pop_up_form');

            if (popUpName === '') {
                return;
            }

            if (POP_UP(popUpName) === false) {
                return;
            }

            let btnFormSubmit = document.querySelector(
                '[data-pop_up_submit=' + popUpName + ']'
            );

            btnFormSubmit.addEventListener('click', () => {
                form.submit();
            });

            event.preventDefault();
        });
    });

    if (popUpOnLoad) {
        POP_UP(popUpOnLoad.getAttribute('data-pop_up_on_load'));
    }
};
