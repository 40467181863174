module.exports = () => {
    window.vavadaCode.JIVO_INIT = function (jivoEvent) {
        let btnsJivoInit = document.querySelectorAll('[data-help=btn_jivo]');

        if (btnsJivoInit.length !== 0) {
            btnsJivoInit.forEach((btn) => {
                btn.classList.remove('visibility_hidden');
                btn.classList.remove('display_none');

                btn.addEventListener('click', jivoEvent);
            });
        }
    };
};
