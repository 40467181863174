// main chunk for all pages of site
// for some terrible reasons, webpack make a disorder of style
// modules, so we are forced to use additionally main.scss files in
// build them in the right order
// https://github.com/webpack/webpack/issues/215

'use strict';

require('js/main.js');
require('sass/main.scss');
require.context('./', true, /\.(png|jpg|jpeg|gif|svg|ico|webp)$/);
