const keysCleaner = require('../tools/keys_cleaner.js');

function userRank(rank) {
    if (!rank) return null;
    const model = {
        current: rank.current_key,
        currentName: rank.current,
        percent: rank.percent,
        progressName: rank.progress,
        progressNextName: rank.progress_next,
    };
    return keysCleaner(model);
}

function userBonus(bonus) {
    if (!bonus) return null;
    const model = {
        bet: bonus.bet,
        betFormatted: bonus.bet_formatted,
        max: bonus.max,
        maxFormatted: bonus.max_formatted,
    };
    return keysCleaner(model);
}

module.exports.userRank = userRank;
module.exports.userBonus = userBonus;
module.exports.user = userData => {
    const model = {
        rank: userRank(userData.rank),
        bonus: userBonus(userData.bonus),
    };

    return keysCleaner(model);
};
