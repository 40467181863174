function appendChildren() {
    Node.prototype.appendChildren = function (arrayOfNodes) {
        const self = this;
        arrayOfNodes.forEach(node => {
            self.appendChild(node);
        });
    };
}

module.exports = appendChildren();
