module.exports = () => {
    const elementsToExpand = document.querySelectorAll('[data-expand]');
    const animationDurationInMs = 500;

    elementsToExpand.forEach(element => {
        const expandListClassName = element.getAttribute('data-expand-class');
        if (!expandListClassName) return;

        const expandedElement = document.querySelector(`.${expandListClassName}`);
        if (!expandedElement) return;

        let expandListHeight;
        if (!expandListHeight) {
            expandListHeight = expandedElement.offsetHeight;
            expandedElement.style.maxHeight = '0px';
            expandedElement.style.transform = 'scaleY(0)';
            expandedElement.style.transformOrigin = 'top';
            const initialExpandedElementTransition =
                window.getComputedStyle(expandedElement).transition;
            const expandTransition =
                `max-height ${animationDurationInMs}ms, transform ${animationDurationInMs}ms`;
            expandedElement.style.transition =
                `${initialExpandedElementTransition}, ${expandTransition}`;
        }

        element.addEventListener('click', () => {
            element.classList.toggle('x_expand_block_opened');

            expandedElement.classList.toggle('x_expand_block_opened');

            if (expandedElement.classList.contains('x_expand_block_opened')) {
                expandedElement.style.maxHeight = `${expandListHeight}px`;
                expandedElement.style.transform = 'scaleY(1)';
                setTimeout(() => {
                    window.scrollTo({
                        top: expandedElement.offsetTop + expandListHeight,
                        behavior: 'smooth',
                    });
                }, animationDurationInMs);
            } else {
                expandedElement.style.maxHeight = '0px';
                expandedElement.style.transform = 'scaleY(0)';
            }
        });
    });
};
