module.exports = selectEntity => {
    // TODO: resolve bug - excess empty object in array
    if (selectEntity.passedElement) {
        let distanceToScroll = 0;
        const element = selectEntity.passedElement.element;
        const list = selectEntity.dropdown.element.querySelector('.choices__list');

        element.addEventListener('choice', (event) => {
            distanceToScroll = list.scrollTop;
        });

        element.addEventListener('showDropdown', () => {
            // UC Browser does not support other then scrollTop scrolling methods
            list.scrollTop = distanceToScroll;
            const allGroups = list.querySelectorAll('.choices__group');
            allGroups.forEach(element => {
                if (element.nextSibling.classList.contains('choices__item')) {
                    element.nextSibling.dataset.content = element.dataset.value;
                }
            });
        });
    }
};
