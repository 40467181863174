module.exports = () => {
    let formInput = document.querySelector('.form_include_box input');
    let formInputToggle = (cssClass) => {
        formInput.classList.toggle(cssClass);
    };

    document.querySelectorAll('[data-toggle=btn]').forEach((item) => {
        item.addEventListener('click', (event) => {
            event.stopPropagation();
            formInput.value = null;
            formInput.value = item.querySelector('[data-toggle=adding]').textContent.trim();

            formInputToggle('js_border_shadow_input');
            setTimeout(() => {
                formInputToggle('ease_in');
                formInputToggle('js_border_shadow_input');
            }, 100);

            setTimeout(() => {
                formInputToggle('ease_in');
            }, 300);
        });
    });
};
