const gap = 10;
const delay = 500;
const animationTime = 250;

const body = document.querySelector('body');
const titleNode = document.createElement('span');
titleNode.classList.add('custom-title');
titleNode.style.display = 'none';
titleNode.style.animationDuration = `${animationTime}ms`;
body.appendChild(titleNode);

module.exports = element => {
    let timeout;
    let isRelevantTitle = false;

    function updateTitle() {
        const value = element.getAttribute('title');
        if (value) {
            titleNode.innerText = value;
            element.removeAttribute('title');
        }
    }

    function initTitle(event) {
        updateTitle();
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            titleNode.style.left = `${event.clientX + window.scrollX + gap}px`;
            titleNode.style.top = `${event.clientY + window.scrollY + gap * 2}px`;
            titleNode.style.display = 'block';
        }, delay);
    }

    const observer = new MutationObserver(() => {
        if (isRelevantTitle) updateTitle();
    });

    observer.observe(element, {
        attributeFilter: ['title'],
    });

    element.addEventListener('mouseenter', () => {
        isRelevantTitle = true;
        element.addEventListener('mousemove', initTitle);
    });

    element.addEventListener('mouseleave', () => {
        isRelevantTitle = false;
        clearTimeout(timeout);
        element.setAttribute('title', titleNode.innerText);
        element.removeEventListener('mousemove', initTitle);
        titleNode.classList.add('x_hide');
        setTimeout(() => {
            titleNode.style.display = 'none';
            titleNode.classList.remove('x_hide');
        }, animationTime);
    });
};
