module.exports = () => {
    let colorDepthInputs = document.querySelectorAll('[data-fingerprint=colorDepth]');
    let widthInputs = document.querySelectorAll('[data-fingerprint=width]');
    let heightInputs = document.querySelectorAll('[data-fingerprint=height]');
    let languageInputs = document.querySelectorAll('[data-fingerprint=language]');
    let timeZoneInputs = document.querySelectorAll('[data-fingerprint=timeZone]');
    let timeZoneOffsetInputs = document.querySelectorAll('[data-fingerprint=timeZoneOffset]');
    let documentWidthInputs = document.querySelectorAll('[data-fingerprint=documentWidth]');
    let documentHeightInputs = document.querySelectorAll('[data-fingerprint=documentHeight]');

    colorDepthInputs.forEach((colorDepthInput) => {
        colorDepthInput.value = screen.colorDepth;
    });
    widthInputs.forEach((widthInput) => {
        widthInput.value = screen.width;
    });
    heightInputs.forEach((heightInput) => {
        heightInput.value = screen.height;
    });
    languageInputs.forEach((languageInput) => {
        languageInput.value = navigator.language;
    });
    timeZoneInputs.forEach((timeZoneInput) => {
        timeZoneInput.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    });
    timeZoneOffsetInputs.forEach((timeZoneOffsetInput) => {
        timeZoneOffsetInput.value = new Date().getTimezoneOffset();
    });
    documentWidthInputs.forEach((documentWidthInput) => {
        documentWidthInput.value = document.body.clientWidth;
    });
    documentHeightInputs.forEach((documentHeightInput) => {
        documentHeightInput.value = document.body.clientHeight;
    });
};
