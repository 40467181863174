module.exports = () => {
    function CREATE_TIMER(view, time) {
        const daysView = view.querySelector('[data-timer=days]');
        const daysText = daysView.getAttribute('data-timer_days_text');
        const hoursView = view.querySelector('[data-timer=hours]');
        const minutesView = view.querySelector('[data-timer=minutes]');
        const secondsView = view.querySelector('[data-timer=seconds]');

        const dateStart = Date.now();

        function UPDATE_TIMER() {
            const deltaTime = time - (Date.now() - dateStart) / 1000;
            if (deltaTime <= 0) {
                clearInterval(timeInterval);
                window.location.reload();
                return;
            }

            const days = Math.floor(deltaTime / (60 * 60 * 24));
            const hours = Math.floor((deltaTime % (60 * 60 * 24)) / (60 * 60));
            const minutes = Math.floor((deltaTime % (60 * 60)) / 60);
            const seconds = Math.floor(deltaTime % 60);

            daysView.innerText = days > 0 ? `${days} ${daysText}` : '';

            if (days === 0 && hours === 0) {
                hoursView.innerText = '';
            } else {
                hoursView.innerText = hours < 10 ? '0' + hours + ' : ' : hours + ' : ';
            }

            minutesView.innerText = minutes < 10 ? '0' + minutes + ' : ' : minutes + ' : ';
            secondsView.innerText = seconds < 10 ? '0' + seconds : seconds;
        }

        const timeInterval = setInterval(UPDATE_TIMER, 1000);
        UPDATE_TIMER();
    }

    return CREATE_TIMER;
};
