const Fetch = require('../tools/fetch.js');
const { user } = require('../models/user.js');
const inViewport = require('../tools/in_viewport.js');
const ranksCollection = require('../constants/rank.js');

const ranksData = {};
Object.keys(ranksCollection).forEach((key, index) => {
    const rank = ranksCollection[key];
    ranksData[rank] = index;
});

function checkRank(rankToCheck) {
    if (ranksData[rankToCheck] === undefined) {
        throw new Error(`This rank '${rankToCheck}' does not exist`);
    }
}

module.exports = url => {
    const currentRankBlock = document.querySelector('[data-progress=current-rank]');
    const titleNode = currentRankBlock.querySelector('[data-rank=title]');
    const imageNode = currentRankBlock.querySelector('[data-rank-image]');
    const timeout = 5000;
    const upgradeAnimationTime = 500;
    const modifierUpgrade = 'x_status-upgrade';
    let currentRank = imageNode.dataset.rankImage;
    let upgrade = 'none';
    imageNode.classList.add('x_animation');
    imageNode.classList.add(`x_${currentRank}`);

    checkRank(currentRank);

    function fillCurrent(rank) {
        checkRank(rank.current);

        imageNode.classList.remove(upgrade);
        imageNode.classList.remove(`x_${currentRank}`);
        imageNode.classList.add(`x_${rank.current}`);

        const deltaRank = ranksData[rank.current] - ranksData[currentRank];
        if (deltaRank === 1) {
            upgrade = `x_${currentRank}-${rank.current}`;
            imageNode.classList.add(upgrade);
            imageNode.classList.add(modifierUpgrade);

            setTimeout(() => {
                imageNode.classList.remove(modifierUpgrade);
            }, upgradeAnimationTime);
        }

        currentRank = rank.current;
        titleNode.innerText = rank.currentName;
    }

    const progressRank = document.querySelector('[data-progress=rank]');
    const current = progressRank.querySelector('[data-rank-bar=current]');
    const next = progressRank.querySelector('[data-rank-bar=next]');
    const nodeProgress = progressRank.querySelector('progress');
    function fillProgressRank(rank) {
        nodeProgress.value = rank.percent;
        nodeProgress.title = `${rank.percent}%`;
        current.innerText = rank.progressName;
        next.innerText = rank.progressNextName;
    }

    const progressBonus = document.querySelector('[data-progress=bonus]');
    const progress = progressBonus.querySelector('progress');
    const modifier = 'x_hidden';
    function fillProgressBonus(bonus) {
        if (bonus) {
            const max = progressBonus.querySelector('[data-bonus=max]');
            max.innerText = bonus.maxFormatted;
            progress.max = bonus.max;
            progress.value = bonus.bet;
            progress.title = bonus.betFormatted;
            progressBonus.classList.remove(modifier);
        } else {
            progress.value = 0;
            setTimeout(() => {
                progressBonus.classList.add(modifier);
            }, timeout / 5);
        }
    }

    function fill(collection) {
        const { rank, bonus } = user(collection);
        if (rank) {
            fillCurrent(rank);
            fillProgressRank(rank);
        }

        fillProgressBonus(bonus);
    }

    const progressBar = document.querySelector('[data-progress]');
    function fetchProgressData() {
        if (inViewport(progressBar)) {
            Fetch(url)
                .then(response => response.json())
                .then(userInfo => {
                    fill(userInfo);
                    setTimeout(fetchProgressData, timeout);
                })
                .catch(rejection => {
                    throw rejection;
                });
        } else {
            setTimeout(fetchProgressData, timeout);
        }
    }

    setTimeout(fetchProgressData, timeout);
};
