module.exports = () => {
    window.vavadaCode.VIVO_INIT = function (vivoEvent) {
        const btnVivoChat = document.querySelectorAll('[data-help="btn_vivo_chat"]');

        if (btnVivoChat.length !== 0) {
            btnVivoChat.forEach((btn) => {
                btn.classList.remove('visibility_hidden');
                btn.classList.remove('display_none');

                btn.addEventListener('click', vivoEvent);
            });
        }
    };
};
