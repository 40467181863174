const maxSize = 5 * 1024 * 1024;

module.exports = () => {
    const boxInputFile = document.querySelectorAll('[data-input_file=box]');

    boxInputFile.forEach((box) => {
        const input = box.querySelector('input[type=file]');
        const status = box.querySelector('[data-input_file=status]');
        const fileNotChoosed = status.innerText.trim();

        status.classList.remove('display_none');

        input.addEventListener('change', () => {
            status.innerText = fileNotChoosed;
            status.classList.remove('x_selected');
            status.classList.remove('x_warning');

            if (input.value) {
                if (input.files[0].size > maxSize) {
                    status.classList.add('x_warning');
                    status.innerText = status.dataset.statusTooLarge;
                    input.value = '';
                } else {
                    status.classList.add('x_selected');
                    status.innerText = input.files[0].name;
                }
            }
        });
    });
};
