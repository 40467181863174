let PADDING_FOR_CURRENCY_CLASS = 'padding_for_currency';
let HIDDEN_CLASS = 'hidden';

module.exports = () => {
    let input = document.querySelector('[data-box-input-with-currency] input');
    let hiddenValue = document.querySelector('.fake_input_value');
    let currencyElement = document.querySelector('.fake_input_currency');
    let currencyCode = document.querySelector('[data-locale]').dataset.currency;

    // converts underscore to dash because some locales come from server with underscore
    let locale = document.querySelector('[data-locale]').dataset.locale.replace(/_/g, '-');

    const numberFormat = new Intl.NumberFormat(
        locale,
        { style: 'currency', currency: currencyCode, currencyDisplay: 'narrowSymbol' }
    ).format(1);
    const currencySymbol = numberFormat.replace(/[\d\s.,]/g, '');
    const isCurrencyPrepend = numberFormat.indexOf(currencySymbol) === 0;

    const toggleCurrencyVisibility = () => {
        currencyElement.classList.toggle(HIDDEN_CLASS, !input.value);
    };

    currencyElement.innerText = currencySymbol;

    if (input.value) {
        currencyElement.classList.remove(HIDDEN_CLASS);
        if (isCurrencyPrepend) {
            input.classList.add(PADDING_FOR_CURRENCY_CLASS);
        } else {
            hiddenValue.innerText = input.value;
        }
    }

    if (isCurrencyPrepend) {
        currencyElement.classList.add('currency_prepend');

        input.addEventListener('input', () => {
            toggleCurrencyVisibility();
            input.classList.toggle(PADDING_FOR_CURRENCY_CLASS, input.value);
        });
    } else {
        input.addEventListener('input', () => {
            toggleCurrencyVisibility();
            hiddenValue.innerText = input.value;
        });
    }
};
