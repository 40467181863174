const inputSuggestion = require('./index');

const params = {
    ['game-search']: require('./game_search.json'),
};

module.exports = inputs => {
    inputs.forEach(input => {
        inputSuggestion(input, params[input.dataset.suggestion] || '');
    });
};
