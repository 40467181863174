const { windowMobileBreakpoint } = require('../../constants/breakpoints.js');
const body = document.querySelector('body');
const eventType = 'click';
const modifier = 'x_show';
let activeGamecard = null;

module.exports = parentNode => {
    const gamecards = parentNode.querySelectorAll('[data-gamecard=show]');

    function switchActive(card) {
        if (activeGamecard) activeGamecard.classList.remove(modifier);
        activeGamecard = card;
    }

    gamecards.forEach(card => {
        function hide(event) {
            event.stopPropagation();
            switchActive(card);
            body.removeEventListener(eventType, hide);
        }

        function show(event) {
            event.stopPropagation();
            if (window.innerWidth <= windowMobileBreakpoint) {
                switchActive(card);
                card.classList.add(modifier);
                body.addEventListener(eventType, hide);
            }
        }

        card.addEventListener(eventType, show);
    });

    window.addEventListener('resize', () => {
        if (window.innerWidth > windowMobileBreakpoint) {
            switchActive(null);
        }
    });
};
