module.exports = () => {
    // toggling *hide* by click on *btn*
    const TOGGLER = {
        ALL_ON(cssClass) {
            document.querySelectorAll('[data-toggle=adding]').forEach((item) => {
                item.classList.add(cssClass);
            });
        },

        ALL_OFF(cssClass) {
            document.querySelectorAll('[data-toggle=adding]').forEach((item) => {
                item.classList.remove(cssClass);
            });
        },

        ONE_OFF_OTHERS_ON(cssClass) {
            document.querySelectorAll('[data-toggle=area]').forEach((item) => {
                let toggleBtn = item.querySelector('[data-toggle=btn]');
                let toggleAdding = item.querySelector('[data-toggle=adding]');

                toggleBtn.addEventListener('click', (event) => {
                    event.stopPropagation(); // using to not fall in parents containers

                    if (!toggleAdding.classList.contains(cssClass)) {
                        toggleAdding.classList.toggle(cssClass);
                    } else {
                        TOGGLER.ALL_ON(cssClass);
                        toggleAdding.classList.toggle(cssClass);
                    }
                });
            });
        },

        ONE_ON_OTHERS_OFF(cssClass) {
            document.querySelectorAll('[data-toggle=area]').forEach((item) => {
                let toggleBtn = item.querySelector('[data-toggle=btn]');
                let toggleAdding = item.querySelector('[data-toggle=adding]');

                toggleBtn.addEventListener('click', (event) => {
                    event.stopPropagation(); // using to not fall in parents containers

                    if (!toggleAdding.classList.contains(cssClass)) {
                        TOGGLER.ALL_OFF(cssClass);
                        toggleAdding.classList.toggle(cssClass);
                    } else {
                        toggleAdding.classList.toggle(cssClass);
                    }
                });
            });
        },

        ALL_BTN_OFF(cssClass) {
            document.querySelectorAll('[data-toggle=zone]').forEach((zone) => {
                zone.querySelectorAll('[data-btn]').forEach((btn) => {
                    btn.classList.remove(cssClass);
                });
            });
        },

        ALL_CONTENT_ON(cssClass) {
            document.querySelectorAll('[data-toggle=zone]').forEach((zone) => {
                zone.querySelectorAll('[data-content]').forEach((content) => {
                    content.classList.add(cssClass);
                });
            });
        },

        TOGGLE_CONTENT(cssClass) {
            document.querySelectorAll('[data-toggle=zone]').forEach((zone) => {
                let allBtns = zone.querySelectorAll('[data-btn]');
                let allContents = zone.querySelectorAll('[data-content]');

                allBtns.forEach((button) => {
                    let selectorByDataValue = '[data-content=' + button.dataset.btn + ']';
                    let contentEqual = zone.querySelector(selectorByDataValue);

                    button.addEventListener('click', (event) => {
                        event.stopPropagation(); // using to not fall in parents containers

                        if (!button.classList.contains('active')) {
                            TOGGLER.ALL_BTN_OFF('active');

                            allContents.forEach((contentView) => {
                                contentView.classList.add(cssClass);
                            });

                            contentEqual.classList.remove(cssClass);

                            button.classList.toggle('active');
                        }
                    });
                });
            });
        },

        TOGGLE_INPUTS_CONTENT(cssClass) {
            document.querySelectorAll('[data-toggle=zone]').forEach((zone) => {
                let allInputs = zone.querySelectorAll('[data-input]');

                function CHECK_INPUTS() {
                    allInputs.forEach((input) => {
                        let contentEqual = zone.querySelectorAll(
                            '[data-content=' + input.dataset.input + ']'
                        );
                        if (typeof input.checked === 'undefined') {
                            input = input.querySelector('input');
                        }

                        contentEqual.forEach((content) => {
                            if (input.checked) {
                                content.classList.remove(cssClass);
                            } else {
                                content.classList.add(cssClass);
                            }
                        });
                    });
                }

                CHECK_INPUTS();

                allInputs.forEach((input) => {
                    input.addEventListener('change', CHECK_INPUTS);
                });
            });
        },
    };

    return TOGGLER;
};
