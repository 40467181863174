const Fetch = require('../../tools/fetch.js');

module.exports = findGamecards => {
    /* use this code for gamecards and game mobile redirect page */
    function CHANGE_FAVORITE(formObject, cardObject) {
        let formInputs = formObject.querySelectorAll('input');
        let method = formObject.getAttribute('method');
        let url = formObject.getAttribute('action');
        let formBody = [];

        formInputs.forEach((input) => {
            formBody
              .push(encodeURIComponent(input.name) +
                '=' +
                encodeURIComponent(input.value));
        });

        formBody = formBody.join('&');

        Fetch(
          url,
          {
            method,
            body: formBody,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        },
        )
          .then(response => response.json())
          .then(jsonValue => {
            if (jsonValue.result === 'OK') {
                cardObject.classList.toggle('favorite');
            }
        })
          .catch(reject => {
            if (reject instanceof Response) {
                document.location.reload();
            }
        });
    }

    let ajaxGamecardFavorite = findGamecards.querySelectorAll('[data-ajax=favorite_gamecard]');

    ajaxGamecardFavorite.forEach((card) => {
        let favoriteForms = card.querySelectorAll('[data-ajax=favorite_form]');

        favoriteForms.forEach((form) => {
            form.addEventListener('submit', (event) => {
                event.preventDefault();
                CHANGE_FAVORITE(form, card);
            });
        });
    });
};
