const classEmpty = 'x_empty';
const classPending = 'x_pending';
const classFilled = 'x_filled';

module.exports.dotsPending = dotsContainer => {
    const emptyDot = dotsContainer.querySelector(`.${classEmpty}`);
    if (emptyDot) emptyDot.classList.add(classPending);
};

module.exports.dotsStopPending = dotsContainer => {
    const pendingDots = dotsContainer.querySelectorAll(`.${classPending}`);
    pendingDots.forEach(dot => dot.classList.remove(classPending));
};

module.exports.dotsFill = dotsContainer => {
    const emptyDot = dotsContainer.querySelector(`.${classEmpty}`);
    if (emptyDot) {
        emptyDot.classList.remove(classEmpty);
        emptyDot.classList.remove(classPending);
        emptyDot.classList.add(classFilled);
    }
};

module.exports.dotsEmpty = dotsContainer => {
    const filledDot = dotsContainer.querySelectorAll(`.${classFilled}:last-child`);
    if (filledDot) {
        filledDot.classList.remove(classFilled);
        filledDot.classList.remove(classPending);
        filledDot.classList.add(classEmpty);
    }
};
