module.exports = () => {
    function RESIZE_GAME_BY_RESIZE_PARENT
        (gameSelector, ratio, obstacleVertical, obstacleHorizontal, objectWithPadding) {
        // ratio for attribute i use for different games with different proportions
        let gameObject = document.querySelector(gameSelector);
        let obstacleForHeight = document.querySelectorAll(obstacleVertical);
        let obstacleForWidth = document.querySelectorAll(obstacleHorizontal);
        let paddingObject = document.querySelector(objectWithPadding);
        let wantedHeight;
        let wantedWidth;
        let obstacleComputedHeight;
        let obstacleComputedWidth;
        let minimalPadding = 0;
        let paddings;

        function SIZE_ADDICTION_OF_PARENT() {
            paddings = 0;

            if (window.innerWidth > 1024) {
                minimalPadding = 1;
            }

            if (paddingObject) {
                paddings = (
                        parseFloat(getComputedStyle(paddingObject).paddingLeft)
                        + minimalPadding
                    ) * 2;
            }

            if (obstacleForHeight.length !== 0) {
                obstacleComputedHeight = 0;
                obstacleForHeight.forEach((obstacle) => {
                    obstacleComputedHeight += parseFloat(getComputedStyle(obstacle).height);
                });

                wantedHeight = window.innerHeight - obstacleComputedHeight - paddings;
            } else {
                wantedHeight = window.innerHeight - paddings;
            }

            if (obstacleForWidth.length !== 0) {
                obstacleComputedWidth = 0;
                obstacleForWidth.forEach((obstacle) => {
                    obstacleComputedWidth += parseFloat(getComputedStyle(obstacle).width);
                });

                wantedWidth = window.innerWidth - obstacleComputedWidth - paddings;
            } else {
                wantedWidth = window.innerWidth - paddings;
            }

            if (wantedHeight * ratio > wantedWidth) {
                gameObject.style.height = Math.round(wantedWidth / ratio) + 'px';
                gameObject.style.width = Math.round(wantedWidth) + 'px';
            } else {
                gameObject.style.height = Math.round(wantedHeight) + 'px';
                gameObject.style.width = Math.round(wantedHeight * ratio) + 'px';
            }
        }

        SIZE_ADDICTION_OF_PARENT();
        window.addEventListener('load', SIZE_ADDICTION_OF_PARENT);
        window.addEventListener('resize', SIZE_ADDICTION_OF_PARENT);
    }

    return RESIZE_GAME_BY_RESIZE_PARENT;
};
