const tracker = require('@snowplow/browser-tracker');

module.exports = () => {
    window.vavadaCode.SNOWPLOW_INIT = function (url, userId) {
        tracker.newTracker('sp1', url, {
            appId: 'vavada',
            plugins: [],
        });
        tracker.setUserId(userId);
        tracker.trackPageView();
    };
};
