const Fetch = require('../tools/fetch.js');

module.exports = (container) => {
    const filterButtons = container.querySelectorAll('[data-jackpot-filter]');
    const targetContainer = container.querySelector('[data-jackpot-ladder-wrapper]');
    let activeButton = container.querySelector('[data-jackpot-filter-toggle].active');
    let loading = false;

    if (filterButtons.length) {
        filterButtons.forEach(filter => {
            filter.addEventListener('click', (event) => {
                event.preventDefault();

                if (loading) {
                    return;
                }

                const currentTarget = event.currentTarget;
                const button = currentTarget.querySelector('[data-jackpot-filter-toggle]');
                const url = currentTarget.href;

                if (activeButton === button) {
                    return;
                }

                loading = true;

                Fetch(url)
                    .then(response => response.json())
                    .then(jsonResponse => {
                        targetContainer.innerHTML = jsonResponse.html;
                    })
                    .then(() => {
                        if (activeButton) {
                            activeButton.classList.toggle('active');
                        }

                        button.classList.toggle('active');
                        activeButton = button;

                        history.replaceState(null, null, url);

                        const ladderCarouselInit = require('./ladder_carousel_init.js');
                        ladderCarouselInit();
                    })
                    .catch(rejection => {
                        throw rejection;
                    })
                    .finally(() => loading = false);
            });
        });
    }
};
