module.exports = () => {
    const jsonrpc = require('jsonrpc-lite');

    const CHAT_CONNECTION_MAXTRIES = 5;
    const CHAT_CONNECTION_DELAY = 10 * 1000;

    class Chat
    {
        constructor(url, token, locale)
        {
            this.translations = {};
            this.url = url;
            this.token = token;
            this.locale = locale;
            this.connection = null;
            this.tryCount = 0;
            this.activeMessage = null;

            this.chatWrapper = document.getElementById('chat');
            this.chatWindow = document.getElementById('chat_window');
            this.input = document.getElementById('chat_message');
            this.button = document.getElementById('chat_button');
            this.chatForm = document.getElementById('chat_form');
            this.chatUserList = document.getElementById('chat_user-list');
            this.buttonAdmin = document.getElementById('chat_admin-button');

            this.authAdmin = false;

            this.rpcMap = {
                auth: this.onRpcAuth.bind(this),
                message: this.onRpcMessage.bind(this),
                warning: this.onRpcWarning.bind(this),
                info: this.onRpcInfo.bind(this),
                sendUserCollection: this.onRpcSendUserCollection.bind(this),
            };

            this.handler = {};
            this.handler.connect = this.connect.bind(this);
            this.handler.onSubmit = this.onSubmit.bind(this);
            this.handler.onConnect = this.onConnect.bind(this);
            this.handler.onClose = this.onClose.bind(this);
            this.handler.onRecieve = this.onRecieve.bind(this);
            this.initAdminMenu = this.initAdminMenu.bind(this);
            this.createUserCollection = this.createUserCollection.bind(this);
            this.getTranslations = this.getTranslations.bind(this);
            this.onToggleChatUserList = this.onToggleChatUserList.bind(this);

            this.chatForm.addEventListener('submit', this.handler.onSubmit, false);

            this.SCROLL_DELTA = () => {
                this.chatWindow.scrollTop =
                    this.chatWindow.scrollHeight - this.chatWindow.clientHeight;
            };

            this.chatBtn = document.querySelector('[data-btn=chat]');

            if (this.chatBtn) {
                this.chatBtn.addEventListener('click', () => {
                    this.SCROLL_DELTA();
                });
            }

            this.getTranslations();
        }

        onSubmit(event)
        {
            event.preventDefault();
            if (this.input.value != '') {
                let data = jsonrpc.notification('message', { text: this.input.value });
                this.send(data);
            }

            this.input.value = '';
        }

        send(data)
        {
            if (this.connection && this.connection.readyState == WebSocket.OPEN) {
                this.connection.send(data);
            }
        }

        auth()
        {
            let data = jsonrpc.notification('auth', { token: this.token, locale: this.locale });
            this.send(data);
        }

        disable()
        {
            this.input.disabled = true;
            this.button.disabled = true;
            this.chatWrapper.classList.remove('x_ready');
            if (this.buttonAdmin) {
                this.buttonAdmin.disabled = true;
                this.toggleChatUserList('remove');
            }
        }

        enable()
        {
            this.input.disabled = false;
            this.button.disabled = false;
            this.chatWrapper.classList.add('x_ready');
            if (this.buttonAdmin) this.buttonAdmin.disabled = false;
        }

        onRpcNotification(notification)
        {
            let methodName = notification.method;
            let params = notification.params;
            let method = this.rpcMap[methodName];
            if (method) {
                method(params);
            }
        }

        escape(string)
        {
            let html = string.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&apos;');

            return html;
        }

        message(message, typeClass)
        {
            let messageDiv = document.createElement('div');
            messageDiv.classList.add('chat_message', typeClass);
            messageDiv.innerHTML = this.escape(message);
            this.chatWindow.appendChild(messageDiv);
        }

        initAdminMenu() {
            this.buttonAdmin.removeEventListener('click', this.onToggleChatUserList);
            this.buttonAdmin.addEventListener('click', this.onToggleChatUserList);
        };

        nullifyActiveMessage(message = this.activeMessage) {
            if (message) message.classList.remove('view');
            this.activeMessage = null;
        }

        onToggleChatUserList() {
            this.nullifyActiveMessage();
            this.toggleChatUserList();
        };

        toggleChatUserList(method = 'toggle') {
            this.buttonAdmin.classList[method]('x_open');
            this.chatUserList.classList[method]('x_open');
            this.chatForm.classList[method]('visibility_hidden');
        };

        createUserCollection(listOfUsers) {
            listOfUsers.forEach(userRecord => {
                const li = document.createElement('li');
                li.classList.add('chat_user-list_item');
                li.classList.add('chat_message');

                const connectionStatus = document.createElement('span');
                connectionStatus.classList.add('chat_user-connection');
                li.appendChild(connectionStatus);
                if (userRecord.isOnline) {
                    connectionStatus.classList.add('x_online');
                }

                const name = document.createElement('span');
                name.innerText = userRecord.name;
                name.classList.add('chat_user-name');
                li.appendChild(name);

                if (userRecord.isAdmin) {
                    const status = document.createElement('span');
                    status.classList.add('chat_user-is-admin');
                    status.innerText = this.translations.status.admin;
                    li.appendChild(status);
                }

                if (userRecord.isBanned) {
                    const banned = document.createElement('span');
                    banned.innerText = this.translations.status.banned;
                    banned.classList.add('chat_user-banned');
                    li.appendChild(banned);
                }

                const params = {};
                Object.keys(userRecord).forEach(key => params[key] = userRecord[key]);
                params.userId = userRecord.id;
                delete params.id;

                this.createAdminOptions(li, params);

                this.chatUserList.appendChild(li);
            });
        }

        createAdminOptions(message, params) {
            message.addEventListener('click', (clickMessage) => {
                clickMessage.stopPropagation();

                if (this.activeMessage) this.activeMessage.classList.remove('view');
                message.classList.add('view');
                this.activeMessage = message;

                let haveOptions = message.querySelector('[data-admin=options]');

                if (haveOptions === null) {
                    const actionToDo = params.isBanned
                      ? 'unBan'
                      : 'ban';

                    let adminOptions = document.createElement('span');
                    adminOptions.classList.add('admin_options');
                    adminOptions.setAttribute('data-admin', 'options');

                    let question = document.createElement('span');
                    question.classList.add('question');

                    const textToDo = this.translations.messages[actionToDo];
                    question.innerText =
                        `${textToDo} ${this.escape(params.name)} #${params.userId}?`;

                    adminOptions.appendChild(question);

                    let answerBox = document.createElement('span');
                    answerBox.classList.add('answer_box');
                    adminOptions.appendChild(answerBox);

                    let answerYes = document.createElement('span');
                    answerYes.classList.add('answer_yes', 'btn_primary_small');
                    answerYes.innerText = this.translations.messages.approve;
                    answerYes.addEventListener('click', (event) => {
                        event.stopPropagation();
                        let data = jsonrpc.notification(actionToDo, { userId: params.userId });
                        this.send(data);
                        this.nullifyActiveMessage(message);
                    });

                    answerBox.appendChild(answerYes);

                    let answerNo = document.createElement('span');
                    answerNo.classList.add('answer_no', 'btn_small');
                    answerNo.innerText = this.translations.messages.decline;
                    answerNo.addEventListener('click', (event) => {
                        event.stopPropagation();
                        this.nullifyActiveMessage(message);
                    });

                    answerBox.appendChild(answerNo);

                    message.appendChild(adminOptions);
                }
            });
        }

        onRpcWarning(params)
        {
            this.message(params.message, 'warning');
        }

        onRpcInfo(params)
        {
            this.message(params.message, 'info');
        }

        onRpcAuth(params)
        {
            if (params.banned) {
                this.disable();
            } else {
                this.enable();
            }

            if (params.isAdmin) {
                this.authAdmin = true;
                this.chatWindow.classList.add('admin');
                this.initAdminMenu();
            }
        }

        onRpcMessage(params)
        {
            let message = document.createElement('p');
            message.classList.add('chat_message');

            if (this.authAdmin) {
                this.createAdminOptions(message, params);
            }

            let name = document.createElement('span');
            name.classList.add('name');
            if (params.isAdmin) {
                name.classList.add('is-admin');
            }

            name.innerHTML = this.escape(params.name);
            message.appendChild(name);

            let text = document.createElement('span');
            text.innerHTML = this.escape(params.text);
            text.classList.add('text');
            message.appendChild(text);

            let userScroll =
                Math.ceil(this.chatWindow.scrollTop) <
                this.chatWindow.scrollHeight - this.chatWindow.clientHeight;

            if (this.chatWindow.childElementCount >= 300) {
                this.chatWindow.removeChild(this.chatWindow.childNodes[0]);
            }

            this.chatWindow.appendChild(message);

            if (userScroll === false) {
                this.SCROLL_DELTA();
            }
        }

        onRpcSendUserCollection(userCollection) {
            if (this.authAdmin && !this.activeMessage) {
                this.chatUserList.innerText = '';

                this.createUserCollection(userCollection);
            }
        }

        onRecieve(packet)
        {
            let notification = jsonrpc.parse(packet.data);
            this.onRpcNotification(notification.payload);
        }

        onClose()
        {
            this.disable();
            setTimeout(this.handler.connect, CHAT_CONNECTION_DELAY);
        }

        clear()
        {
            this.chatWindow.innerHTML = '';
        }

        onConnect()
        {
            this.tryCount = 0;
            this.clear();
            this.auth();
        }

        connect()
        {
            if (this.tryCount++ > CHAT_CONNECTION_MAXTRIES) {
                return false;
            }

            try {
                this.connection = new WebSocket(this.url);
                this.connection.onopen = this.handler.onConnect;
                this.connection.onmessage = this.handler.onRecieve;
                this.connection.onclose = this.handler.onClose;
            } catch (error) {
                console.log(error.name + ' : ' + error.message + '\n' + error.stack);
                return false;
            }
        }

        getTranslations() {
            const elementsOfTranslations = this.chatWrapper.querySelectorAll('[data-translations]');
            elementsOfTranslations.forEach(element => {
                let translations = element.dataset.translations;
                translations = JSON.parse(translations);
                Object.keys(translations).forEach(key => {
                    this.translations[key] = translations[key];
                });
            });
        }
    }

    const chatWrapper = document.querySelector('#chat');

    const url = chatWrapper.dataset.url;
    const token = chatWrapper.dataset.token;
    const locale = chatWrapper.dataset.locale;
    const chat = new Chat(url, token, locale);
    chat.connect();
};
