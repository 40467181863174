module.exports = () => {
    let customValueBox = document.querySelectorAll('[data-custom=box]');

    customValueBox.forEach((box) => {
        let btns = box.querySelectorAll('[data-custom=btn]');
        let text = box.querySelector('[data-custom=input]');

        btns.forEach((btn) => {
            btn.addEventListener('click', () => {
                text.value = btn.innerHTML;
            });
        });
    });

    let bankCardPageKey = document.querySelector('[data-key=bank_card]');
    if (bankCardPageKey) {
        let moduleCcFormatter = require('../component/method_number_formatter.js');
        moduleCcFormatter('[data-method_number_formatter=cc]', /[^0-9]/gi, 4);
    }

    let ibanPageKey = document.querySelector('[data-key=iban]');
    if (ibanPageKey) {
        let moduleIbanFormatter = require('../component/method_number_formatter.js');
        moduleIbanFormatter('[data-method_number_formatter=iban]', /[^a-z0-9]/gi, 4);
    }

    const walletList = document.querySelector('[data-choose="game_currency"]');
    const switchButton = document.querySelector('[data-btn="button_switch"]');
    switchButton.addEventListener('click', () => {
        walletList.classList.toggle('hidden');
    });
};
