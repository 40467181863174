module.exports = element => {
    const bounding = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);

    return (
        !document.hidden &&
        bounding.top < window.innerHeight &&
        bounding.bottom > 0 &&
        bounding.left < window.innerWidth &&
        bounding.right > 0 &&
        bounding.height > 0 &&
        bounding.width > 0 &&
        style.visibility !== 'hidden' &&
        style.opacity !== '0'
    );
};
