module.exports = () => {
    let boxInputPassword = document.querySelectorAll('[data-input_password=box]');

    boxInputPassword.forEach((box) => {
        let input = box.querySelector('input');
        let eye = box.querySelector('[data-input_password=eye]');

        eye.addEventListener('click', () => {
            if (input.type === 'password') {
                input.type = 'text';
                eye.classList.add('open');
            } else {
                input.type = 'password';
                eye.classList.remove('open');
            }
        });
    });
};
