module.exports = () => {
    let selectors = document.querySelectorAll('[data-select=radio]');

    selectors.forEach((selector) => {
        let choosed = selector.querySelector('[data-select=choosed]');
        let inputsList = selector.querySelector('[data-select=list]');
        let inputs = inputsList.querySelectorAll('input');

        /* IOS 10.2.1 bugfix:
         element not clickable, even if it button and has tabindex
         (and it must be clickable because it has CSS :focus event),
         element *choosed* fully clickable since version IOS 11.2.5 */
        choosed.addEventListener('click', () => {
        });

        function CHECKED_VALUE(forCheck) {
            if (forCheck.checked) {
                let item = forCheck.parentNode.querySelector('[data-select=item]');
                choosed.innerHTML = item.innerHTML;
            }
        }

        inputs.forEach((input) => {
            CHECKED_VALUE(input);

            input.addEventListener('click', () => {
                CHECKED_VALUE(input);
            });
        });
    });
};
