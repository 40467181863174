require('../../tools/append_children.js');

module.exports = (select) => {
    const getFirstLetter = node => (node.innerHTML.trim().charAt(0).toLowerCase());

    const createOptgroup = (label, arrayOfOptions) => {
        const optgroup = document.createElement('optgroup');
        optgroup.appendChildren(arrayOfOptions);
        optgroup.label = label;
        return optgroup;
    };

    const newNodesForSelect = [];
    const letterGroupCollection = {};
    const optionSelected = select.querySelector('option[selected]');
    const selectedValue = optionSelected ? optionSelected.value : null;
    const optionsArray = select.querySelectorAll('option');
    select.innerHTML = '';
    let allProvidersOption;

    optionsArray.forEach(option => {
        if (option.dataset.allProviders) {
            allProvidersOption = option;
            return;
        }

        const firstLetter = getFirstLetter(option);
        if (letterGroupCollection[firstLetter]) {
            letterGroupCollection[firstLetter].push(option);
        } else {
            letterGroupCollection[firstLetter] = [option];
        }
    });

    if (allProvidersOption) {
        newNodesForSelect.push(createOptgroup(
            getFirstLetter(allProvidersOption), [allProvidersOption])
        );
    }

    Object.keys(letterGroupCollection).forEach(letter => {
        newNodesForSelect.push(createOptgroup(letter, letterGroupCollection[letter]));
    });

    select.appendChildren(newNodesForSelect);
    select.value = selectedValue || newNodesForSelect[0].firstChild.value;
};
