const Fetch = require('../tools/fetch.js');
const inViewport = require('../tools/in_viewport.js');

module.exports = () => {
    function AJAX_HTML(htmlSelector) {
        let boxForHtml = document.querySelectorAll(htmlSelector);

        boxForHtml.forEach((box) => {
            const timeInterval = 10000;
            const url = box.getAttribute('data-url');
            let isFirstTry = true;

            function AJAX_INVOKE() {
                if (inViewport(box) || isFirstTry) {
                    isFirstTry = false;
                    Fetch(url)
                        .then(response => response.text())
                        .then(html => {
                            box.innerHTML = html;
                            setTimeout(AJAX_INVOKE, timeInterval);
                        })
                        .catch(rejection => {
                            throw rejection;
                        });
                } else {
                    setTimeout(AJAX_INVOKE, timeInterval);
                }
            }

            AJAX_INVOKE();
        });
    }

    return AJAX_HTML;
};
