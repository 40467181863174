module.exports = node => {
    const initAmount = parseInt(node.dataset.jackpotAmount);
    let amount = initAmount;
    const delay = parseInt(node.dataset.jackpotDelay);
    const locale = node.dataset.jackpotLocale;
    const currency = node.dataset.jackpotCurrency;
    const initTimestamp = Date.now();
    let timeoutId;

    const insertJackpotValue = () => {
        let localizedValue;

        try {
            localizedValue = (amount / 100).toLocaleString(
                locale,
                {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'narrowSymbol',
                });
        } catch (e) {
            // It's a fallback for older browsers that don't support narrowSymbol
            localizedValue = (amount / 100).toLocaleString(
                locale,
                {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'code',
                }).replace('USD', '$');
        }

        node.innerText = localizedValue;
    };

    // When browser tab changed
    window.addEventListener('visibilitychange', () => {
        if (document.hidden) {
            clearTimeout(timeoutId);
        } else {
            amount = initAmount + (Date.now() - initTimestamp) / delay;
            insertJackpotValue();

            setTimeout(() => requestAnimationFrame(updateJackpotValue), delay);
        }
    });

    const updateJackpotValue = () => {
        amount++;
        insertJackpotValue();

        const nextAmount = amount + 1;
        const nextAmountDelta = nextAmount - initAmount;
        const nextTimestamp = initTimestamp + nextAmountDelta * delay;
        const timeout = nextTimestamp - Date.now();

        timeoutId = setTimeout(() => requestAnimationFrame(updateJackpotValue), timeout);
    };

    setTimeout(() => requestAnimationFrame(updateJackpotValue), delay);
};
