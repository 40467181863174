module.exports = () => {
    const TRANSITION_TIMEOUT_FLASHES = 5000;
    const TRANSITION_TIME = 250;

    const flashesCollection = document.querySelectorAll('[data-flash]');

    const closeFlash = (closeButton, flash) => {
        closeButton.removeEventListener('click', closeFlash);
        flash.classList.remove('visible');
        setTimeout(() => {
            flash.remove();
        }, TRANSITION_TIME);
    };

    flashesCollection.forEach((flash) => {
        flash.classList.add('visible');
        const closeButton = flash.querySelector('[data-flash-close-button]');

        closeButton.addEventListener('click', () => {
            closeFlash(closeButton, flash);
        });

        setTimeout(() => {
            closeFlash(closeButton, flash);
        }, TRANSITION_TIMEOUT_FLASHES);
    });
};
