const lineClamp = require('line-clamp');

module.exports = node => {
    const flipButtons = node.querySelectorAll('[data-bonus-card=flip]');
    const flipContainer = node.querySelector('[data-bonus-card=container]');
    const backSide = flipContainer.querySelector('.bonus-card_side--back');
    const bonusHeaders = node.querySelectorAll('.bonus-card_header');

    /* In Safari 16.4+, also in other browsers in iPadOS/iOS 16.4+
     * after flipping element, events working wrong
     * Due to the inability to use click events, we disable the flipping animation
     *
     * Regular expression for detecting Safari and iPadOS/iOS version
     */
    const MINIMAL_VERSION = 16.4;

    const regexp =
        /version\/(?<version>[\w.,]+) .*safari|(?:iPhone|iPad).*OS (?<versionOS>[\d_]+)/i;
    const match = window.navigator.userAgent.match(regexp);

    let version = null;
    if (match && match.groups) {
        if (match.groups.version) {
            version = parseFloat(match.groups.version);
        } else if (match.groups.versionOS) {
            version = parseFloat(match.groups.versionOS.replace('_', '.'));
        }
    }

    let isRotationNotAvailable = version && version >= MINIMAL_VERSION;

    const onButtonFlipClick = () => {
        if (isRotationNotAvailable) {
            backSide.classList.toggle('x_rotate');

            return;
        }

        const rotatedItemClassName = 'x_rotating_card';
        node.classList.add(rotatedItemClassName);
        setTimeout(() => {
            node.classList.remove(rotatedItemClassName);
        }, 500);
        flipContainer.classList.toggle('x_rotate');
    };

    Array.from(flipButtons).forEach(flipButton => {
        flipButton.addEventListener('click', onButtonFlipClick);
    });

    Array.from(bonusHeaders).forEach(bonusHeader => {
        lineClamp(bonusHeader, 2);
    });
};
