module.exports = () => {
    let scrollersHoriz = document.querySelectorAll('[data-scroll=horizontal]');

    scrollersHoriz.forEach((scroller) => {
        let item = scroller.querySelector('[data-scroll=item]');

        if (item) {
            let scrollerWidth = parseFloat(getComputedStyle(scroller).width);
            let itemWidth = parseFloat(getComputedStyle(item).width);
            let padding = (scrollerWidth - itemWidth) / 2;
            let itemLeft = item.getBoundingClientRect().left;

            scroller.scrollLeft = itemLeft - padding;
        };
    });
};
