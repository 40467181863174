module.exports = () => {
    const moduleToggler = require('../../component/toggle_one_all.js');
    const TOGGLER = moduleToggler();
    TOGGLER.TOGGLE_CONTENT('display_none');

    const gameMenu = document.querySelector('[data-game=menu]');
    gameMenu.classList.add('display_none');
    gameMenu.classList.add('x_init');

    const moduleGameMenuBtns = require('../../component/game_menu_btns.js');
    moduleGameMenuBtns(gameMenu);

    if (document.querySelector('[data-ladder]')) {
        const moduleAjaxList = require('../../component/ajax_html.js');
        const AJAX_LADDER = moduleAjaxList();
        AJAX_LADDER('[data-ajax=html]');
    }

    if (document.getElementById('chat')) {
        const chatWebSocket = require('../../component/chat.js');
        chatWebSocket();
    }
};
