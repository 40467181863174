module.exports = ({ form, iframeModifier, onLoad }) => {
    const autosubmitName = form.getAttribute('data-autosubmit');
    const autosubmitIframe = document.createElement('iframe');

    autosubmitIframe.name = autosubmitName;
    autosubmitIframe.classList.add('autosubmitIframe', 'x_hide', iframeModifier);
    autosubmitIframe.setAttribute('allow', 'clipboard-write *');
    autosubmitIframe.addEventListener('load', (event) => {
        const iframe = event.target;
        try {
            if (iframe.contentWindow.location.href === 'about:blank') {
                return;
            }
        } catch (error) {
            /* SecurityError for all, Permission denied for Edge */
            if (error.name !== 'SecurityError' && error.message !== 'Permission denied') {
                throw error;
            }
        }

        iframe.classList.remove('x_hide');
        if (onLoad) onLoad(event);
    });

    form.parentElement.insertBefore(autosubmitIframe, form);
    form.target = autosubmitName;
    form.submit();
};
