module.exports = () => {
    let orientationTimeout = null;
    let resizeTimeout = null;
    const delay = 250;

    const setSportBlockOffset = () => {
        const page = document.querySelector('.sport_block');
        const offset = page.getBoundingClientRect().top + window.scrollY;

        document.documentElement.style.setProperty('--sport-viewport-offset', `${offset}px`);
    };

    const setSportViewportHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--sport-viewport-height', `${vh}px`);
    };

    const onOrientationChange = () => {
        clearTimeout(orientationTimeout);
        orientationTimeout = setTimeout(setSportViewportHeight, delay);
    };

    const onResizeChange = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(setSportBlockOffset, delay);
    };

    window.addEventListener('resize', onResizeChange);
    window.addEventListener('orientationchange', onOrientationChange);

    setSportViewportHeight();
    setSportBlockOffset();
};
